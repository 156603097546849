<template>
  <div>
    <div class="cardTitle">jcook商品库</div>
	<div class="search-box">
        <a-row>
            <a-col :span="20">
            <a-space size="large">
				<a-form-model layout="inline">
					<a-form-model-item label="sku编码" >
						<a-input v-model="searchForm.jcookSkuId" placeholder="请输入" style="width: 200px" ></a-input>
					</a-form-model-item>
					<a-form-model-item label="上架状态" >
						<a-select v-model="searchForm.status" style="width: 200px">
							<a-select-option :value="0">下架</a-select-option>
							<a-select-option :value="1">上架</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item label="商品名称">
						<a-input v-model="searchForm.skuName"  style="width: 200px" placeholder="请输入"></a-input>
					</a-form-model-item>
					<a-form-model-item label="店铺名称">
						<a-input v-model="searchForm.shopName"  style="width: 200px" placeholder="请输入"></a-input>
					</a-form-model-item>
					<a-form-model-item label="供应商名称">
						<a-input v-model="searchForm.vendorName"  style="width: 200px" placeholder="请输入"></a-input>
					</a-form-model-item>
					<a-form-model-item label="品牌名称">
						<a-input v-model="searchForm.brandName"  style="width: 200px" placeholder="请输入"></a-input>
					</a-form-model-item>
					<a-form-model-item label="分类">
						<a-cascader v-model="casVal" :options="typeData" @change="typeChange" placeholder="请选择"
							:field-names="{ label: 'name', value: 'name', children: 'shopCategoryVoList' }">
						</a-cascader>
					</a-form-model-item>
					<a-form-model-item>
						<a-button type="primary" @click='getData'>查 询</a-button>
					</a-form-model-item>
					<a-form-model-item>
						<a-button @click='reset'>重 置</a-button>
					</a-form-model-item>
				</a-form-model>
            </a-space>
            </a-col>
    	</a-row>
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :scroll="{ x: 2600 }"
      @change="handleTableChange"
	  :loading="loading"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: selectionChoosed,
		getCheckboxProps: (record)=>({
			props: {
				disabled: record.status != 1
			}
		})
      }"
      :row-key=" (record, index) => { return record.id; } "
    >
      <span slot="action" slot-scope="text, row">
        <a-space>
          <a v-if="row.status == 1 && row.appShopPush == 0" class="ant-dropdown-link" @click="push(row.id)">上架推送</a>
        </a-space>
      </span>
	  <span slot="mainPhoto" slot-scope="text, row">
        <img
          :src="row.mainPhoto"
          class="table-img"
          alt=""
        />
      </span>
    </a-table>
    <div class="action">
      <a-dropdown :disabled="!hasSelected">
        <a-menu slot="overlay" @click="handleMenuClick">
          <a-menu-item key="on"> 批量上架</a-menu-item>
        </a-menu>
        <a-button> 批量操作 <a-icon type="down" /> </a-button>
      </a-dropdown>
      <span style="margin-left: 8px">
        <template v-if="hasSelected">
          {{ `已选择 ${selectedRowKeys.length} 条` }}
        </template>
      </span>
    </div>
    <addForm
      :show="drawer.show"
      @success="success"
      @addClose="addClose"
      :goodsId="goodsId"
    ></addForm>
  </div>
</template>

<script>
import { cateList } from "@/api/shop/goods/cate.js";
import { jcookGoodsList,jcookGoodsBatchPush } from "@/api/shop/goods";
import { columns, pagination } from "./depend/config";
import addForm from "./depend/form.vue";
export default {
	components: {
		addForm,
	},
	data() {
		return {
			drawer: {
				show: false,
			},
			goodsId: null,
			tableData: [],
			searchForm: {
				jcookSkuId:'',
				skuName:'',
				shopName:'',
				vendorName:'',
				brandName:'',
				status:undefined,
				categoryFirstName: undefined,
				categorySecondName: undefined,
				categoryThirdName: undefined,
			},
			typeData:[],
			casVal:[],
			loading: false,
			columns,
			pagination,
			// 选择的index
			selectedRowKeys: [],
		};
	},
	mounted() {
		this.getApi();
		this.getData();
	},
	methods: {
		getApi() {
			cateList().then(res => {
				let data = res.data;
				this.typeData = data;
			})
		},
		async getData() {
			this.loading = true
			let res = await jcookGoodsList({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				...this.searchForm
			});
			this.tableData = res.data.rows;
			this.pagination.total = res.data.total;
			this.selectedRowKeys = [];
			this.loading = false;
		},
		reset() {
			this.searchForm= {
				jcookSkuId:'',
				skuName:'',
				shopName:'',
				vendorName:'',
				brandName:'',
				status:undefined,
				categoryFirstId: undefined,
				categorySecondId: undefined,
				categoryThirdId: undefined,
			};
			this.casVal = [];
			this.getData()
		},
		push(id) {
			this.goodsId = id;
			this.drawer.show = true;
		},
		handleMenuClick(data) {
			if (data.key === "on") {
				this.onSell(this.selectedRowKeys);
			}
		},
		onSell(ids) {
			this.$confirm({
				title: "是否上架",
				icon: "caret-up",
				onOk: async () => {
					let res = await jcookGoodsBatchPush({ ids: ids });
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.getData();
					} else {
						this.$message.error(res.msg);
					}
				},
			});
		},
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
		addClose() {
			this.drawer.show = false;
			this.goodsId = null;
		},
		success() {
			this.getData();
		},
		typeChange(value) {
			this.searchForm.categoryFirstName = value[0];
			this.searchForm.categorySecondName = value[1];
			this.searchForm.categoryThirdName = value[2];
		}
	},
	computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>
<style lang="less" scoped>
.table-img {
  width: 40px;
  height: 40px;
}
</style>
