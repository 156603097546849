export const columns = [
	{
		title: "商品名称",
		width: "8%",
		dataIndex: "skuName",
	},
	{
		title: "主图url",
		width: "4%",
		dataIndex: "mainPhoto",
		scopedSlots: { customRender: "mainPhoto"}
	},
	{
		title: "sku编码",
		width: "8%",
		dataIndex: "skuId",
	},
	{
		title: "店铺名",
		width: "8%",
		dataIndex: "shopName",
	},
	{
		title: "品牌名",
		width: "8%",
		dataIndex: "brandName",
	},
	{
		title: "供应商名",
		width: "8%",
		dataIndex: "vendorName",
	},
	{
		title: "一级分类名称",
		width: "5%",
		dataIndex: "categoryFirstName",
	},
	{
		title: "二级分类名称",
		width: "5%",
		dataIndex: "categorySecondName",
	},
	{
		title: "三级分类名称",
		width: "5%",
		dataIndex: "categoryThirdName",
	},
	{
		title: "上架状态",
		width: "5%",
		dataIndex: "status",
		customRender: function (status) {
			switch (status) {
			case false:
				return '下架'
				break;
			case true:
				return '上架'
			default:
				break;
			}
		}
	},
	{
		title: "app商品库推送状态",
		width: "5%",
		dataIndex: "appShopPush",
		customRender: function (appShopPush) {
			switch (appShopPush) {
			case false:
				return '未推送'
				break;
			case true:
				return '已推送'
			default:
				break;
			}
		}
	},
	{
		title: "供货价",
		width: "5%",
		dataIndex: "supplyPrices",
	},
	{
		title: "指导价",
		width: "5%",
		dataIndex: "guidePrices",
	},
	{
		title: "更新时间",
		width: "8%",
		dataIndex: "updatedAt",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const formItem = [
	{
		type: 'input',
		label:'sku编码',
		prop:'jcookSkuId',
		placeholder:'请输入'
	},
	{
		type: 'select',
		label:'上架状态',
		prop:'status',
		option:[{ id:0,name:'下架'},{ id:1,name:'上架'},],
		placeholder:'请选择状态'
	},
	{
		type: 'input',
		label:'商品名称',
		prop:'skuName',
		placeholder:'请输入'
	},
	{
		type: 'input',
		label:'店铺名',
		prop:'shopName',
		placeholder:'请输入'
	},
	{
		type: 'input',
		label:'供应商名称',
		prop:'vendorName',
		placeholder:'请输入'
	},
	{
		type: 'input',
		label:'品牌名称',
		prop:'brandName',
		placeholder:'请输入'
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 200,
	showTotal: (total) => `共 ${total} 条`,
	pageSizeOptions: ['10','50','100','200'],
	showSizeChanger: true,
	showQuickJumper: true,
}