<template>
  <div>
    <a-drawer
      title="jcook商品上架推送"
      :width="720"
      :visible="show"
      :body-style="{ paddingBottom: '60px' }"
      @close="addClose"
    >
      <div class="drawer-content">
        基本信息
        <a-divider></a-divider>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          layout="vertical"
        >
          <a-row>
            <a-form-model-item prop="defaultSkuName" label="使用默认商品名称">
              <a-switch v-model="form.defaultSkuName"></a-switch>
            </a-form-model-item>
            <a-form-model-item
              v-if="form.defaultSkuName === false"
              prop="skuName"
              label="自定义商品名称"
            >
              <a-input v-model="form.skuName" style="width: 60%"></a-input>
            </a-form-model-item>
            <a-form-model-item prop="defaultShopName" label="使用默认店铺">
              <a-switch v-model="form.defaultShopName"></a-switch>
            </a-form-model-item>
            <a-form-model-item
              v-if="form.defaultShopName === false"
              prop="shopId"
              label="自定义选择店铺"
            >
              <a-select v-model="form.shopId" style="width: 60%">
				  <a-select-option v-for="item in options.shop" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
			  </a-select>
            </a-form-model-item>
            <a-form-model-item prop="defaultVendorName" label="使用默认供应商">
              <a-switch v-model="form.defaultVendorName"></a-switch>
            </a-form-model-item>
            <a-form-model-item
              v-if="form.defaultVendorName === false"
              prop="vendorId"
              label="自定义选择供应商"
            >
              <a-select v-model="form.vendorId" style="width: 60%">
				  <a-select-option v-for="item in options.vendor" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
			  </a-select>
            </a-form-model-item>
            <a-form-model-item prop="defaultBrandName" label="使用默认品牌">
              <a-switch v-model="form.defaultBrandName"></a-switch>
            </a-form-model-item>
            <a-form-model-item
              v-if="form.defaultBrandName === false"
              prop="brandId"
              label="自定义选择品牌"
            >
              <a-select v-model="form.brandId" style="width: 60%">
				  <a-select-option v-for="item in options.brand" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
			  </a-select>
            </a-form-model-item>
            <a-form-model-item prop="defaultCategoryName" label="使用默认分类">
              <a-switch v-model="form.defaultCategoryName"></a-switch>
            </a-form-model-item>
            <a-form-model-item
              v-if="form.defaultCategoryName === false"
              prop="cateValue"
              label="自定义选择分类"
            >
              <a-cascader
                v-model="cateValue"
                :options="options.cate"
                placeholder="请选择分类"
                :field-names="{
                  label: 'name',
                  value: 'id',
                  children: 'shopCategoryVoList',
                }"
                @change="change"
                style="width: 60%"
              ></a-cascader>
            </a-form-model-item>
            <a-form-model-item prop="sellPrice" label="售卖价">
              <a-input v-model="form.sellPrice" style="width: 60%"></a-input>
            </a-form-model-item>
            <a-form-model-item prop="discountPrice" label="划线价">
              <a-input
                v-model="form.discountPrice"
                style="width: 60%"
              ></a-input>
            </a-form-model-item>
          </a-row>
        </a-form-model>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="addClose">
          关闭
        </a-button>
        <a-button type="primary" @click="submit"> 提交 </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { form, rules, options } from "./form.js";
import { cateSelect,shopSelect,vendorSelect,brandSelect,jcookGoodsPush } from "@/api/shop/goods"
export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		goodsId: Number,
	},
	data() {
		return {
			form,
			rules,
			options,
			fileList: [],
			cateValue: [],
		};
	},
	mounted() {
		this.getItem();
	},
	methods: {
		getItem() {
			// 分类 店铺 供应商 品牌
			Promise.all([cateSelect(),shopSelect(),vendorSelect(),brandSelect()]).then(res=>{
				this.options.cate = res[0].data
				this.options.shop = res[1].data
				this.options.vendor = res[2].data
				this.options.brand = res[3].data
			})
		},
		addClose() {
			this.$refs.ruleForm.resetFields();
			this.form.categoryFirstId = undefined;
			this.form.categorySecondId = undefined;
			this.form.categoryThirdId = undefined;
			this.cateValue = [];
			this.$emit("addClose");
		},
		success() {
			this.$emit("success");
			this.addClose();
		},
		change(data) {
			this.form.categoryFirstId = data[0];
			this.form.categorySecondId = data[1];
			this.form.categoryThirdId = data[2];
		},
		submit() {
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					let res = await jcookGoodsPush(this.form);
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.success();
					} else {
						this.$message.error(res.msg);
					}
				}
			});
		},
	},
	watch: {
		goodsId: {
			handler(val) {
				if (val !== null) {
					this.form.id = val;
				}
			},
			immediate: true,
		},
	},
};
</script>

<style></style>
